import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appButtonLoading]'
})
export class ButtonLoadingDirective implements AfterViewInit {
  @Input() set submitting(value: boolean) {
    this.isSubmitted = value;
    this.addLoadingClass();
  }

  get submitting(): boolean {
    return this.isSubmitted;
  }

  @Input() prefix: string = '';

  private isSubmitted: boolean;
  private buttonElement: HTMLButtonElement;

  constructor(
    private el: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    this.buttonElement = (this.el.nativeElement.getElementsByClassName('p-button') as HTMLCollection)
      .item(0) as HTMLButtonElement;
  }

  private addLoadingClass(): void {
    if (this.buttonElement) {
      const method = this.isSubmitted ? 'add' : 'remove';

      this.buttonElement.classList[method]('crm-blue--loading');
    }
  }
}
