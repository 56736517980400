import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { DotsUtilsModule } from '@dots-connect-it/utils';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  exports: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    DotsUtilsModule,
    SharedModule
  ]
})
export class LoaderModule { }
