import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { JwtService } from '@dots-connect-it/core';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  constructor(public jwtService: JwtService, public router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.jwtService.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }

    const expectedRole = route.data.expectedRole;
    const token: any = this.jwtService.getDecodedAccessToken();

    if (!(expectedRole.indexOf(token.role) > -1)) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
