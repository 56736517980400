import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppMissingTranslationHandler } from '@core/translate/missing-translation-handler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppInjector } from './app-injector.service';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng-lts/api';
import { NotificationModule } from '@dots-connect-it/utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng-lts/dropdown';
import { ButtonModule } from 'primeng-lts/button';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import localeRoMd from '@angular/common/locales/ro-MD';
import { registerLocaleData } from '@angular/common';
import { NonRouteComponent } from './features/non-route/non-route.component';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { NgxsModule } from '@ngxs/store';
import { ngxsConfig } from '../ngxs.config';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

registerLocaleData(localeRoMd, 'ro-MD');

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    NonRouteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NotificationModule,
    BrowserAnimationsModule,
    DropdownModule,
    ButtonModule,
    ConfirmDialogModule,
    LoaderModule,
    NgxsModule.forRoot([], ngxsConfig),
    NgxsStoragePluginModule.forRoot(), // TODO just for development purpose
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production })
  ],
  providers: [TranslateService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
