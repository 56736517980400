import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public show$ = this.loader.asObservable();

  public showLoader(): void {
    this.loader.next(true);
  }

  public hideLoader(): void {
    this.loader.next(false);
  }
}
