import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '@dots-connect-it/core';
import { AppRoutes } from '@core/enums/generic/routes.enum';
import { Role } from '@core/enums/generic/role.enum';

@Component({
  selector: 'app-non-route',
  template: ''
})
export class NonRouteComponent implements OnInit {

  constructor(
    private readonly jwtService: JwtService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (!this.jwtService.isAuthenticated()) {
      this.router.navigate(['/', AppRoutes.auth]);
    } else {
      const token: any = this.jwtService.getDecodedAccessToken();
      if (token.role === Role.user) {
        this.router.navigate(['/', AppRoutes.landing]);
      } else {
        this.router.navigate(['/', AppRoutes.dashboard]);
      }
    }
  }

}
