import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AppRoutes } from '@core/enums/generic/routes.enum';
import { JwtService } from '@dots-connect-it/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(
    private readonly jwtService: JwtService,
    public router: Router
  ) {
  }

  public canActivate(): boolean {
    if (!this.jwtService.isAuthenticated()) {
      this.router.navigate([AppRoutes.auth, AppRoutes.login]);

      return false;
    }

    return true;
  }

  public canLoad(): boolean {
    if (!this.jwtService.isAuthenticated()) {
      this.router.navigate([AppRoutes.auth, AppRoutes.login]);

      return false;
    }

    return true;
  }
}
