import { Component, OnInit } from '@angular/core';
import { DestroyBase } from '@dots-connect-it/utils';
import { LoaderService } from '@core/services/loader.service';
import { ProgressService } from '@dots-connect-it/api';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent extends DestroyBase implements OnInit {
  public show = false;
  public progress$: Observable<number>;

  constructor(
    public progressService: ProgressService,
    private readonly loaderService: LoaderService
  ) {
    super();
    this.progress$ = this.progressService.progress$;
  }

  public ngOnInit(): void {
    this.loaderService.show$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(visible => this.show = visible);
  }
}
