enum LandingRoutes {
  landing = 'landing',
  home = 'home',
  scanner = 'scanner',
  filters = 'filters',
  menu = 'menu',
  stations = 'stations'
}

enum DashboardRoutes {
  user = 'user',
  role = 'role',
  homeDashboard = 'home-dashboard',
  analytic = 'analytic',
  customer = 'customer',
  reports = 'reports',
  setting = 'setting',
  support = 'support',
  profileSetting = 'profile-setting',
  password = 'password',
  import = 'import',
  station = 'station',
  indication = 'indication',
  profile = 'profile',
  dashboard = 'dashboard'
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
  register = 'register',
  forgot = 'forgot',
  reset = 'reset'
}

enum CommonRoutes {
  detail = 'detail',
  view = 'view',
  edit = 'edit'
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...LandingRoutes,
  ...DashboardRoutes,
  ...AuthRoutes,
  ...CommonRoutes
};

