import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/generic/routes.enum';
import { JwtService } from '@dots-connect-it/core';
import { ValidatorInterceptor, ValidatorService } from '@dots-connect-it/forms';
import { NotificationService } from '@dots-connect-it/utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CodeInterceptor extends ValidatorInterceptor implements HttpInterceptor {
  constructor(
    protected notificationService: NotificationService,
    protected validatorService: ValidatorService,
    private readonly jwtService: JwtService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    super(validatorService, notificationService);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Do something on success execution
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 400:
                this.parseValidations(err.error);
                break;
              case 401:
                this.jwtService.logout();
                this.router.navigate([AppRoutes.auth, AppRoutes.login]);
                const title = this.translateService.instant('general.info');
                this.notificationService.info(title, err.error.messages.non_field);
                break;
              default:
                // this.snackBar.open(err.error.error.message, 'Done');
                break;
            }
          }
        }
      )
    );
  }
}
