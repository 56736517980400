<dots-notification></dots-notification>
<app-loader></app-loader>
<p-confirmDialog #cd>
  <p-footer>
    <div class="confirmation-actions">
      <a class="g-link" (click)="cd.reject()">{{ 'actions.cancel' | translate }}</a>
      <button pButton
              (click)="cd.accept()"
              [label]="'actions.confirm' | translate">
      </button>
    </div>
  </p-footer>
</p-confirmDialog>
<router-outlet></router-outlet>
