import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs/operators';

@Pipe({
  name: 'loading'
})
export class LoadingPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService
  ) {
  }

  transform(label: string, submitted: Observable<boolean>): Observable<string> {
    if (submitted) {
      return submitted.pipe(
        mergeMap((result) => {
          const key = result ? 'actions.loading' : label;

          return this.translateService.stream(key);
        })
      );
    }

    return this.translateService.stream(label);
  }
}
