import { Injectable } from '@angular/core';
import { ApiService } from '@dots-connect-it/api';
import { Setting } from '@model/entity/setting.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TranslateProperty } from '@model/helpers/translate-property.model';
import { first } from 'rxjs/operators';
import { SettingKey } from '@core/enums/generic/setting-key.enum';

@Injectable({
  providedIn: 'root'
})
export class SettingApiService extends ApiService<Setting> {
  public settingLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public settingLoaded$: Observable<boolean> = this.settingLoaded.asObservable();
  protected endpoint = 'setting';
  private settings: Setting[];

  public getSettingValue(key: SettingKey): Observable<TranslateProperty> {
    if (!this.settings) {
      return of(null);
    }

    const setting = this.settings.find(item => item.key === key);

    return setting ? of(setting.value) : of(null);
  }

  public extractPublicSettings(): void {
    this.active().pipe(first()).subscribe((response) => {
      this.settings = response.data;
      this.settingLoaded.next(true);
    });
  }
}
