import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { CodeInterceptor } from '@core/interceptors/code.interceptor';
import { DotsCoreModule } from '@dots-connect-it/core';
import { SettingApiService } from '@core/api/setting-api.service';
import { DotsApiModule } from '@dots-connect-it/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    DotsApiModule.forRoot({
      baseUrl: environment.baseUrl
    }),
    DotsCoreModule.forRoot(
      TranslateService,
      {
        baseUrl: environment.baseUrl,
        SettingService: SettingApiService
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CodeInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
}
