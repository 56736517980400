import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'everySelected'
})
export class EverySelectedPipePipe implements PipeTransform {
  transform(selected: any[], data: any[] = [], property: string = 'value'): boolean {
    return data.every(item => !!selected.includes(item[property]));
  }
}
