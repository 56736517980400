import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@core/guards/auth-guard.service';
import { RoleGuardService } from '@core/guards/role-guard.service';
import { Role } from '@core/enums/generic/role.enum';
import { NonRouteComponent } from './features/non-route/non-route.component';


const routes: Routes = [
  {
    path: '',
    component: NonRouteComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService, RoleGuardService],
    data: { expectedRole: [Role.admin] },
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'landing',
    canActivate: [AuthGuardService, RoleGuardService],
    data: { expectedRole: [Role.user] },
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
