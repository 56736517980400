import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UploadAvatarComponent } from './components/upload-avatar/upload-avatar.component';
import { DotsUtilsModule } from '@dots-connect-it/utils';
import { NoResultComponent } from './components/no-result/no-result.component';
import { LoadingPipe } from '@shared/pipes/loading.pipe';
import { ButtonLoadingDirective } from '@shared/directives/button-loading.directive';
import { EverySelectedPipePipe } from './pipes/every-selected.pipe';

const BASE_COMPONENTS = [
  UploadAvatarComponent,
  NoResultComponent
];

const BASE_PIPES = [
  LoadingPipe,
  EverySelectedPipePipe
];

const BASE_DIRECTIVES = [
  ButtonLoadingDirective
];

@NgModule({
  declarations: [
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES
  ],
  imports: [
    CommonModule,
    DotsUtilsModule,
    TranslateModule
  ],
  exports: [
    TranslateModule,
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES
  ]
})
export class SharedModule {
}
