import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CSSVarNames, DotsThemeService } from '@dots-connect-it/theming';
import { DestroyBase } from '@dots-connect-it/utils';
import { ValidatorService } from '@dots-connect-it/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyBase implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly themingService: DotsThemeService,
    private readonly validatorService: ValidatorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setMultiLanguageProperties();
    this.setThemeProperties();
    this.setValidationsText();
  }

  private setMultiLanguageProperties(): void {
    this.translateService.addLangs(['ro']);
    this.translateService.setDefaultLang('ro');
    this.translateService.use('ro');
  }

  private setThemeProperties(): void {
    this.themingService.setProps({
      [CSSVarNames.Primary]: '#fff',
      [CSSVarNames.Font]: 'roboto regular',
      [CSSVarNames.DotsNotificationDanger]: '#c00',
      [CSSVarNames.DotsDataTableDivider]: 'transparent',
      [CSSVarNames.DotsDataTableCellHeight]: '50px',
      [CSSVarNames.DotsDataTableRowBackground]: 'transparent',
      [CSSVarNames.DotsDataTableBorderColor]: '#f3f3f3',
      [CSSVarNames.DotsDataTableThBackground]: '#eef1f5',
      [CSSVarNames.DotsDataTableThColor]: '#a8b5be',
      [CSSVarNames.Danger]: '#c00',
      [CSSVarNames.DotsNotificationInfo]: '#054cff',
      [CSSVarNames.DotsUploaderHeight]: '100px',
      [CSSVarNames.DotsUploaderBorder]: '1px dashed #054cff',
      [CSSVarNames.DotsUploaderPreviewBackground]: '#fff'
    });
  }

  private setValidationsText(): void {
    this.translateService.onLangChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.validatorService.setDefaultMessages(
        {
          required: this.translateService.instant('validation-messages.required'),
          email: this.translateService.instant('validation-messages.email'),
          minlength: this.translateService.instant('validation-messages.minlength'),
          maxlength: this.translateService.instant('validation-messages.maxlength'),
          min: this.translateService.instant('validation-messages.min'),
          max: this.translateService.instant('validation-messages.max'),
          pattern: this.translateService.instant('validation-messages.pattern'),
          passwordNotEquals: this.translateService.instant('validation-messages.passwordNotEquals'),
        }
      );
    });
  }
}
